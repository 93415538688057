export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS"
export const PROJECT_LIST_FAILURE = "PROJECT_LIST_FAILURE"
export const PROJECT_LIST_LOADING = "PROJECT_LIST_LOADING"
export const SET_PROJECT = "SET_PROJECT"
export const SET_PROJECT_NEIGHBOURS = "SET_PROJECT_NEIGHBOURS"
export const SET_PROJECT_NEIGHBOURS_DATA = "SET_PROJECT_NEIGHBOURS_DATA"
export const REMOVE_PROJECT_NEIGHBOURS = "REMOVE_PROJECT_NEIGHBOURS"
export const SET_PROJECT_LOCATION = "SET_PROJECT_LOCATION"
export const SET_USER_INPUTS = "SET_USER_INPUTS"
export const SET_MANUAL_INPUTS = "SET_MANUAL_INPUTS"
export const SET_LAYOUT_VIEW = "SET_LAYOUT_VIEW"
export const SET_TEMP_COORDS = "SET_TEMP_COORDS"

export const projectListSuccess = (data: any) => ({
  type: PROJECT_LIST_SUCCESS,
  payload: data
})

const projectListLoading = () => ({
  type: PROJECT_LIST_LOADING
})

const projectListFailure = () => ({
  type: PROJECT_LIST_FAILURE
})

export const setProjectView = (data: any) => ({
  type: SET_PROJECT,
  payload: data
})

export const setProjectNeighbours = (data: any) => ({
  type: SET_PROJECT_NEIGHBOURS,
  payload: data
})

export const setProjectNeighboursData = (data: any) => ({
  type: SET_PROJECT_NEIGHBOURS_DATA,
  payload: data
})

export const removeProjectNeighbours = (index: number) => ({
  type: REMOVE_PROJECT_NEIGHBOURS,
  payload: index
})

// Location
export const setProjectLocation = (data: any) => ({
  type: SET_PROJECT_LOCATION,
  payload: data
})

// UserInputs
export const setUserInputs = (data: any) => ({
  type: SET_USER_INPUTS,
  payload: data
})

export const setManualInputs = (data: any) => ({
  type: SET_MANUAL_INPUTS,
  payload: data
})



// LayoutView
export const setLayoutView = (data: any) => ({
  type: SET_LAYOUT_VIEW,
  payload: data
})

export const setTempCoords = (data: any) => ({
  type: SET_TEMP_COORDS,
  payload: data
})


const actions = {
  projectListFailure,
  projectListLoading,
  projectListSuccess,
  setLayoutView,
  removeProjectNeighbours,
  setTempCoords
}

export default actions
