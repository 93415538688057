export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_FAILURE = "USER_LIST_FAILURE"
export const USER_LIST_LOADING = "USER_LIST_LOADING"
export const SET_USER = "SET_USER"
export const SET_USER_LOCATION = "SET_USER_LOCATION"
export const SET_USER_INPUTS = "SET_USER_INPUTS"
export const SET_LAYOUT_VIEW = "SET_LAYOUT_VIEW"
export const SET_COUNTRIES = "SET_COUNTRIES"

export const userListSuccess = (data: any) => ({
  type: USER_LIST_SUCCESS,
  payload: data
})

const userListLoading = () => ({
  type: USER_LIST_LOADING
})

const setCountries = (countries: any[]) => ({
  type: SET_COUNTRIES,
  payload: countries
})

const userListFailure = () => ({
  type: USER_LIST_FAILURE
})

export const setUserView = (data: any) => ({
  type: SET_USER,
  payload: data
})

// Location
export const setUserLocation = (data: any) => ({
  type: SET_USER_LOCATION,
  payload: data
})

// UserInputs
export const setUserInputs = (data: any) => ({
  type: SET_USER_INPUTS,
  payload: data
})

// LayoutView
export const setLayoutView = (data: any) => ({
  type: SET_LAYOUT_VIEW,
  payload: data
})

const actions = {
  userListFailure,
  userListLoading,
  userListSuccess,
  setLayoutView,
  setCountries
}

export default actions
