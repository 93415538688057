import * as React from "react"

import PurchaseCard, { IPurchase } from "./card"

import LoadingHolder from "@/components/reusables/loading"
import ProfileItemsHeading from "./../profile-items-heading"
import { Stack } from "@mui/material"
import { getEndpoint } from "@/api"
import { useFetch } from "@/hooks"
import { useTranslation } from "react-i18next"

interface IUserPurchasesProfileProps {}

const UserPurchasesProfile: React.FunctionComponent<
  IUserPurchasesProfileProps
> = (props) => {
  //Getting translation object
  const { t } = useTranslation(["common", "inputValidations"])

  //Fetching purchases history
  const { data, loading } = useFetch<any>(getEndpoint("purchase-history"))

  const purchases: IPurchase[] = (data && (data as any)?.projects) || [] || []

  if (loading) return <LoadingHolder height={"240px"} />

  return (
    <Stack direction="column">
      <ProfileItemsHeading
        title={t("common:purchases_history")}
        hasBorder={false}
      />
      {purchases.map((item) => (
        <PurchaseCard key={item._id} item={item} />
      ))}
    </Stack>
  )
}

export default UserPurchasesProfile
