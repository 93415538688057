import * as React from "react"

const useKeys = (action: Function, keys: number[] = []) => {
  React.useEffect(() => {
    //On key change
    const onKeyChange = (e) => {
      if (keys.includes(e.keyCode)) {
        action()
      }
    }

    document.addEventListener("keyup", onKeyChange)

    return () => {
      document.removeEventListener("keyup", onKeyChange)
    }
  }, [keys])
}
export default useKeys
