/**
 * Place all route names here
 * @example ```js
 * {
 *     "assets": "/assets",
 *     "assetDetails": "/assets/:id"
 * }
 * ```
 */

const routes = {
  home: "/",
  superAdminLogin: "/admin",

  login: "/login",
  signUp: "/sign-up",
  dashboard: {
    adminDashboard:"/admin-dashboard",
    users:"/users",
    index: "/dashboard",
    projects: {
      index: "projects",
      show: {
        index: "show/:id",
        tab: ":tab"
      }
    }
  },
  forgetPassword: {
    index: "/password-reset",
    withToken: "/password-reset/:token"
  }
}

export default routes
