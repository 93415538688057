import * as React from "react"

import { Grid, Skeleton, Stack } from "@mui/material"

import { layoutViewSelector } from "@/redux/projects/selectors"
import { useAppSelector } from "@/redux"

interface IProjectSkeletonItemsProps {}

const ProjectSkeletonItems: React.FunctionComponent<
  IProjectSkeletonItemsProps
> = () => {
  const currentLayoutView = useAppSelector(layoutViewSelector)

  const isGrid = currentLayoutView === "grid"

  const RenderContent = () => {
    switch (isGrid) {
      case true:
        return (
          <Grid container spacing={3}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((xx, key) => (
              <Grid
                item
                lg={isGrid ? 3 : 12}
                md={isGrid ? 3 : 12}
                sm={isGrid ? 3 : 12}
                xs={12}
                key={key}
              >
                <Skeleton variant="rectangular" sx={{ height: 180 }} />
              </Grid>
            ))}
          </Grid>
        )
      case false:
        return (
          <Stack
            sx={{
              bgcolor: (t) => t.palette.common.white,
              borderRadius: "8px",
              p: 2
            }}
            spacing={2}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => (
              <Skeleton
                variant="rectangular"
                key={key}
                sx={{ height: 40, borderRadius: 2 }}
              />
            ))}
          </Stack>
        )
    }
  }

  return <RenderContent />
}

export default ProjectSkeletonItems
