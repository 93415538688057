import { AxiosError, AxiosResponse } from "axios"
import { UserCreateType, UserListPaginationType } from "./types"
import { api, getEndpoint } from "@/api"
import { setUserInputs, setUserView, userListSuccess } from "./actions"

import { Dispatch } from "redux"
import { User } from "@/components/normal/Users/items"

// import { Project } from "@/components/normal/projects/card"

export const userCreatApi =
  (
    data: UserCreateType,
    setSubmitting: (isSubmitting: boolean) => void,
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void,
    user?: User
  ) =>
  async (dispatch: Dispatch) => {
    setSubmitting(true)
    const isEdit = Boolean(user)
    try {
      const url = isEdit
        ? getEndpoint("userEdit", {
            id: "" + user?._id
          })
        : getEndpoint("userCreate")
      const response = await api({
        method: isEdit ? "put" : "post",
        url,
        data
      })
      onSuccess(response)
      //To-do: toast message here
    } catch (e: any) {
      onError(e)
      // To-do toast message here
    } finally {
      setSubmitting(false)
    }
  }

export const userDeleteApi =
  (
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void,
    user?: User
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("userDelete", {
        id: "" + user?._id
      })

      const response = await api.delete<any>(url)
      onSuccess(response)
      //To-do: toast message here
    } catch (e: any) {
      onError(e)
      // To-do toast message here
    } finally {
    }
  }

export const userListApi =
  (params: UserListPaginationType, onSuccess?: Function, onError?: Function) =>
  async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("users")
      const response = await api.get<any>(url, { params: params })
      if (response.data.status === 200)
        dispatch(userListSuccess(response.data.users))
      //To-do: toast message here
      if (onSuccess) onSuccess()
    } catch (e) {
      // To-do toast message here
      if (onError) onError()
    } finally {
      // setSubmitting(false);
      if (onError) onError()
    }
  }

export const projectViewApi = (id: string) => async (dispatch: Dispatch) => {
  try {
    const url = getEndpoint("projectView", { id: id })
    const { data } = await api.get<any>(url)
    dispatch(setUserView(data.project))
    //To-do: toast message here
  } catch (e) {
    // To-do toast message here
  } finally {
    // setSubmitting(false);
  }
}

export const projectPartialApi =
  (
    id: string,
    type: string,
    body: any,
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("projectPartial", {
        id: id,
        type: type
      })
      const { data } = await api.post<any>(url, body)
      dispatch(setUserView(data.project || data))
      onSuccess(data.project || data)
    } catch (e: any) {
      console.log(e.response?.data)
      // To-do toast message here
      onError(e.response?.data)
    } finally {
    }
  }

export const projectPricingApi =
  (
    id: string,
    code: string,
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("projectPartial", {
        id: id,
        type: "pricing"
      })
      const { data } = await api.post<any>(url, code ? { code: code } : {})
      onSuccess(data)
    } catch (e: any) {
      // To-do toast message here
      onError(e)
    } finally {
    }
  }

export const userInputsApi =
  (section = "") =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUserInputs([]))
      const url = getEndpoint("userInput")
      const { data } = await api.get<any>(`${url}?filters[section]=${section}`)
      let inputs: any = {}
      if (data.data instanceof Array) {
        dispatch(
          setUserInputs(
            data.data.map((item: any) => item.attributes.JsonObject)
          )
        )
      }

      //To-do: toast message here
    } catch (e) {
      // To-do toast message here
    } finally {
      // setSubmitting(false);
    }
  }
