import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userActions from "./actions";
import reducer from "./redcuer";
import * as userSelectors from "./selectors";

const persistConfig = {
  key: "users",
  storage: storage,
};

const userReducer = persistReducer(persistConfig, reducer);

export { userActions, userReducer, userSelectors };
