import { AuthLogin, AuthRegister, AuthSocialLogin } from "./types"
import { AxiosError, AxiosResponse } from "axios"
import { api, getEndpoint } from "@/api"

import { Dispatch } from "redux"
import { authSuccess } from "./actions"

export const authRegister =
  (
    data: AuthRegister,
    setSubmitting: (isSubmitting: boolean) => void,
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void
  ) =>
  async (dispatch: Dispatch) => {
    setSubmitting(true)
    try {
      const url = getEndpoint("auth-register")
      const response = await api.post<any>(url, data)
      onSuccess(response)
      //To-do: toast message here
    } catch (e: any) {
      onError(e)
      // To-do toast message here
    } finally {
      setSubmitting(false)
    }
  }

export const authLogin =
  (
    data: AuthLogin,
    setSubmitting: (isSubmitting: boolean) => void = () => {},
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: any) => void
  ) =>
  async (dispatch: Dispatch) => {
    setSubmitting(true)
    try {
      const url = getEndpoint("auth-login")
      const response = await api.post<any>(url, data)
      onSuccess(response)
      dispatch(authSuccess(response.data.sessions.token, response.data.user))
      //To-do: toast message here
    } catch (e) {
      // To-do toast message here
      onError(e)
    } finally {
      // setSubmitting(false);
    }
  }

  export const authSocialLogin =
  (
    data: AuthSocialLogin,
    setSubmitting: (isSubmitting: boolean) => void = () => {},
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: any) => void
  ) =>
  async (dispatch: Dispatch) => {
    setSubmitting(true)
    try {
      const endpoint: any = "social-auth";
      const url = getEndpoint(endpoint)
      const response = await api.post<any>(url, data)
      onSuccess(response)
      dispatch(authSuccess(response.data.sessions.token, response.data.user))
      //To-do: toast message here
    } catch (e) {
      // To-do toast message here
      onError(e)
    } finally {
      // setSubmitting(false);
    }
  }

export const adminAuthLogin =
  (
    data: AuthLogin,
    setSubmitting: (isSubmitting: boolean) => void = () => {},
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: any) => void
  ) =>
  async (dispatch: Dispatch) => {
    setSubmitting(true)
    try {
      const url = getEndpoint("admin-auth-login")
      const response = await api.post<any>(url, data)
      onSuccess(response)
      dispatch(authSuccess(response.data.sessions.token, response.data.user))
      //To-do: toast message here
    } catch (e) {
      // To-do toast message here
      onError(e)
    } finally {
      // setSubmitting(false);
    }
  }

export const authSendEmail =
  (onSuccess: Function) => async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("auth-send-email")
      const response = await api.post<any>(url)
      onSuccess(response)
      //To-do: toast message here
    } catch (e) {
      // To-do toast message here
    } finally {
    }
  }

export const authVerifyEmail =
  (
    otp: string,
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("auth-verify-email")
      const response = await api.put<any>(url, { otp: otp })
      onSuccess(response)
      //To-do: toast message here
    } catch (e: any) {
      onError(e?.response?.data)
      // To-do toast message here
    } finally {
    }
  }

export const updateProfile =
  (
    data: any,
    onSuccess: (res: AxiosResponse) => void,
    onError: (err: AxiosError) => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const url = getEndpoint("profile")
      const response = await api.put<any>(url, data)
      onSuccess(response)
      //To-do: toast message here
    } catch (e: any) {
      onError(e)
      // To-do toast message here
    } finally {
    }
  }
