import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
  TableSortLabel
} from "@mui/material"
import React, { ChangeEvent, useState } from "react"

import { Link } from "react-router-dom"
import { MyTableProps } from "."

/**
 * This component is our Table Reusable Component That Recieve four prop:
 * title:our table title,
 *
 * moreLink: with the prop we cas show our showMore button to see all of our installments list,
 *
 * tableHeadItems:this is an array with many objects,in this objects we have two property:
 * 1-title:with this property we named our tableCells in our tablehead
 * 2-render:with this method we return our tableCell items that we use in tableBody
 *
 * items:our TableBody Items
 *
 * @returns {React.ReactElement}
 */

const MyTable: React.FC<MyTableProps> = ({
  title = "",
  moreLink,
  tableHeadItems = [],
  items = [],
  selectable = false,
  sx = {},
  hasCollapse = false,
  onChangeSelectedIds
}): React.ReactElement => {
  const theme = useTheme()
  //Collect item id
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')
  const [valueToOrderBy, setValueToOrderBy] = useState("")
  // const [selectedIds, setSelectedIds] = useState()

  //Fire onChangeSelected
  const updateSelectedEvent = (items: string[]) => {
    if (onChangeSelectedIds && typeof onChangeSelectedIds === "function")
      onChangeSelectedIds(items)
  }

  const handleSortRequest = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === "asc")
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? "desc" : "asc")
  }

  const descComparator = (a, b, orderBy) => {
    let value1 = b[orderBy];
    let value2 = a[orderBy];
    
    if (typeof value1 === "string") value1 = value1.toLowerCase()
    if (typeof value2 === "string") value2 = value2.toLowerCase()

    if (value1 < value2) {
      return -1
    }
    if (value1 > value2) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc' ?
      (a, b) => descComparator(a, b, orderBy)
      : (a, b) => -descComparator(a, b, orderBy)
  }

  const sortedRows = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, ind) => [el, ind]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedRowArray.map(el => el[0])
  }

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property)
  }

  //Function to store item id
  const handleSelectId = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    if (selectedIds.indexOf(id) !== -1) {
      const newIds = selectedIds.filter((item) => item !== id)

      setSelectedIds(newIds)

      //Update event listener
      updateSelectedEvent(newIds)
    } else {
      const newIds = [...selectedIds, id]
      setSelectedIds(newIds)

      //Update event listener
      updateSelectedEvent(newIds)
    }
  }

  return (
    <Box sx={sx}>
      {title && (
        <Box
          sx={{
            ["& a"]: {
              textDecoration: "none"
            },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2
          }}
        >
          {title && <Typography variant="headerBold">{title}</Typography>}
          {moreLink && (
            <Link to={moreLink}>
              <Button variant="text">مشاهده بیشتر</Button>
            </Link>
          )}
        </Box>
      )}
      <TableContainer sx={{ height: "90%" }}>
        <Table
          sx={{
            "& :last-child td, &:last-child th :not(thead)": { border: 0 }
          }}
          stickyHeader
        >
          <TableHead
            sx={{
              ["& tr th"]: {
                whiteSpace: "nowrap",
                fontWeight: "bold",
                fontSize: 14
              }
            }}
          >
            <TableRow>
              {/** Selectable th */}
              {selectable && <TableCell>#</TableCell>}
              {/** Selectable th */}
              {tableHeadItems.map((item, key) => (
                item.title === "Active Status" || item.title === "" ?
                  (<TableCell key={key}>
                    {item.title}
                  </TableCell>)
                  :
                  (<TableCell key={key}>
                    <TableSortLabel
                      active={valueToOrderBy === item.key}
                      direction={valueToOrderBy === item.key ? orderDirection : 'asc'}
                      onClick={createSortHandler(item.key)}>
                      {item.title}
                    </TableSortLabel>
                  </TableCell>)
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              ["& td"]: {
                whiteSpace: "nowrap",
                py: 1
              },
              ["& tr:nth-child(2n)"]: {
                bgcolor: (t) => alpha(t.palette.common.black, 0.02)
              }
            }}
          >
            {sortedRows(items, getComparator(orderDirection, valueToOrderBy)).map((item: any, rowKey: any) => {
              //Selected value
              const isSelectedCheckbox = selectedIds.indexOf(item?.id) !== -1

              return (
                <TableRow
                  key={rowKey}
                  sx={{
                    [`&:hover td`]: {
                      bgcolor: (t) =>
                        `${alpha(t.palette.common.black, 0.04)} !important`
                    }
                  }}
                >
                  {/** Selectable checkbox */}
                  {selectable && (
                    <TableCell>
                      <Checkbox
                        checked={isSelectedCheckbox}
                        onChange={(e) => handleSelectId(e, item?.id)}
                      />
                    </TableCell>
                  )}
                  {/** Selectable checkbox */}
                  {tableHeadItems.map((tdItem, tdKey) => (
                    <TableCell colSpan={tdItem.colSpan} key={tdKey}>
                      {tdItem.render(item)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default MyTable
