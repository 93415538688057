import { StateType } from "@/redux/createStore"
import { createSelector } from "reselect"

export const userListLoadingSelector = createSelector(
  (state: StateType) => state.users,
  (users) => users.userListLoading
)

export const usersListSelector = createSelector(
  (state: StateType) => state.users,
  (users) => users.users
)

export const userSelector = createSelector(
  (state: StateType) => state.users,
  (users) => users.user
)

export const getCountriesSelector = createSelector(
  (state: StateType) => state.users,
  (users) => users.countries
)

// export const userInputSelector = createSelector(
//   (state: StateType) => state.projects,
//   (projects) => projects.userInputs
// )

// export const layoutViewSelector = createSelector(
//   (state: StateType) => state.projects,
//   (projects) => projects.layoutView
// )
