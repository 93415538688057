import * as React from "react"
import * as Yup from "yup"

import { Button, Stack, TextField } from "@mui/material"
import { StateType, store } from "@/redux"
import { api, getEndpoint } from "@/api"

import { LoadingButton } from "@mui/lab"
import ProfileItemsHeading from "./profile-items-heading"
import UserProfile from "."
import { toaster } from "@/components/reusables/toaster"
import { useFormik } from "formik"
import { useGlobalContext } from "@/contexts/g-context"
import { useTranslation } from "react-i18next"

interface IFormikValues {
  newPassword: string
  oldPassword: string
}

interface IUserEditPasswordProps {}

const UserEditPassword: React.FunctionComponent<IUserEditPasswordProps> = (
  props
) => {
  const { t } = useTranslation(["common", "inputValidations"])

  const { showModal, closeModal } = useGlobalContext()

  const backToProfile = () => {
    closeModal()
    showModal(<UserProfile />)
  }

  const {
    values,
    setErrors,
    isSubmitting,
    errors,
    touched,
    handleSubmit,
    getFieldProps
  } = useFormik<IFormikValues>({
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().required(
        t("inputValidations:this_field_is_required")
      ),
      oldPassword: Yup.string().required(
        t("inputValidations:this_field_is_required")
      )
    }),
    initialValues: {
      newPassword: "",
      oldPassword: ""
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true)
      api
        .post(getEndpoint("changePassword"), values)
        .then((res) => {
          toaster.success(t("common:your_password_has_been_changed"))
          actions.setSubmitting(false)
          //Password  has been changed
          backToProfile()
        })
        .catch((err) => {
          actions.setSubmitting(false)
          toaster.error(err)
        })
    }
  })

  return (
    <Stack direction="column" component="form" onSubmit={handleSubmit}>
      <ProfileItemsHeading title={t("common:edit_profile")} />
      <Stack direction="column" spacing={2} sx={{ p: 2, mt: 2 }}>
        <TextField
          type="password"
          label={t("common:old_password")}
          {...getFieldProps("oldPassword")}
          error={Boolean(touched.oldPassword && errors.oldPassword)}
          helperText={touched.oldPassword && errors.oldPassword}
        />
        <TextField
          type="password"
          label={t("common:new_password")}
          {...getFieldProps("newPassword")}
          error={Boolean(touched.newPassword && errors.newPassword)}
          helperText={touched.newPassword && errors.newPassword}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <Button variant="outlined" sx={{ px: 6 }} onClick={backToProfile}>
          {t("common:cancel")}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          {t("common:submit")}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default UserEditPassword
