import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import projectsActions from "./actions";
import reducer from "./redcuer";
import * as projectsSelectors from "./selectors";

const persistConfig = {
  key: "projects",
  storage: storage,
};

const projectsReducer = persistReducer(persistConfig, reducer);

export { projectsActions, projectsReducer, projectsSelectors };
