
export const SET_PASSIVE_DATA = "SET_PASSIVE_DATA"
export const DELETE_PASSIVE_VALUE = "DELETE_PASSIVE_VALUE"
export const CLEAR_PASSIVE_VALUE = "CLEAR_PASSIVE_VALUE"
export const SET_PASSIVE_PCP_DATA = "SET_PASSIVE_PCP_DATA"
export const SET_ACTIVE_PCP_DATA = "SET_ACTIVE_PCP_DATA"
export const SET_OFFSET_PCP_DATA = "SET_OFFSET_PCP_DATA"
export const SET_PASSIVE_DATA_EXISTING = "SET_PASSIVE_DATA_EXISTING"


export const setPassiveData = (data: any) => ({
  type: SET_PASSIVE_DATA,
  payload: data
})
export const setPassiveDataFromExistingData = (data: any) => ({
  type: SET_PASSIVE_DATA_EXISTING,
  payload: data
})

export const deletePassiveValue = (data: any) => ({
  type:DELETE_PASSIVE_VALUE,
  payload: data
})
export const clearPassiveValue = (data: any) => ({
  type:CLEAR_PASSIVE_VALUE,
  payload: data
})
export const setPassivePcpData = (data: any) => ({
  type: SET_PASSIVE_PCP_DATA,
  payload: data
})
export const setActivePcpData = (data: any) => ({
  type: SET_ACTIVE_PCP_DATA ,
  payload: data
})
export const setOffsetPcpData = (data: any) => ({
  type: SET_OFFSET_PCP_DATA,
  payload: data
})
// LayoutView


