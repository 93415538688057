import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./redcuer";
import * as manualSelectors from "./selectors";

const persistConfig = {
  key: "manualData",
  storage: storage,
};

const manualReducer =  reducer;

export { manualReducer, manualSelectors };
