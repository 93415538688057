import * as React from "react"

import { CircularProgress, Stack, Typography } from "@mui/material"

interface ILoadingHolderProps {
  text?: string
  size?: number
  height?: string,
  className?: string
}

const LoadingHolder: React.FunctionComponent<ILoadingHolderProps> = ({
  text,
  size = 24,
  height = "100vh",
  className = "",
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className={className}
      sx={{ py: 4, height }}
      spacing={2}
    >
      <CircularProgress size={size} />
      {text && <Typography variant="bodyBold">{text}</Typography>}
    </Stack>
  )
}

export default LoadingHolder
