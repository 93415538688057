import { StateType } from "@/redux/createStore"
import { createSelector } from "reselect"

export const projectListLoadingSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.projectListLoading
)

export const projectLoadingSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.projectLoading
)

export const projectsListSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.projects
)

export const projectTotalCountSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.projectsTotalCount
)

export const projectSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.project
)

export const userInputSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.userInputs
)
export const manualInputSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.manualInputs
)
export const passivePcpDataSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.passivePcpData
)

export const layoutViewSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.layoutView
)


export const tempCoordsSelector = createSelector(
  (state: StateType) => state.projects,
  (projects) => projects.tempCoords
)
