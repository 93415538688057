import * as React from "react"

import { SxProps, Theme } from "@mui/material"

import { FModal } from "@/components/reusables/modal"
import { ModalGravity } from "@/components/reusables/modal/interface"

interface IGlobalContext {
  showModal: (element: React.ReactNode, gravity?: ModalGravity, sx?: SxProps<Theme>) => void
  closeModal: () => void
}

const initialState: IGlobalContext = {
  showModal: (element: React.ReactNode, gravity?: ModalGravity, sx?: SxProps<Theme>) => {},
  closeModal: () => {}
}

const GlobalContext = React.createContext(initialState)

export const useGlobalContext = () => React.useContext(GlobalContext)

const GContext: React.FC = ({ children }) => {
  const [modalGravity, setModalGravity] = React.useState<ModalGravity>("modal")
  const [isShowModal, setShowModal] = React.useState(false)
  const [sxModal, setSxModal] = React.useState<SxProps<Theme>>({});
  const [modalContext, setModalContent] = React.useState<React.ReactNode>(null)
  const showModal = (element: React.ReactNode, gravity?: ModalGravity, sx?: SxProps<Theme>) => {
    if (gravity) setModalGravity(gravity)
    if ( sx ) setSxModal(sx)
    setModalContent(element)
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
    setSxModal({});
    setModalGravity("modal")
  }

  return (
    <GlobalContext.Provider
      value={{
        showModal,
        closeModal
      }}
    >
      <FModal
        open={isShowModal}
        handleClose={closeModal}
        p={"0 !important"}
        gravity={modalGravity}
        sx={sxModal}
      >
        {modalContext}
      </FModal>
      {children}
    </GlobalContext.Provider>
  )
}
export default GContext
