import * as React from "react"

import { Box, CircularProgress } from "@mui/material"

import HeaderProjectToolbar from "@/containers/page-wrapper/header-projects-toolbar"
import PageWrapper from "@/containers/page-wrapper"
import ProjectItems from "@/components/normal/projects/items"
import ProjectSkeletonItems from "@/components/normal/projects/skeleton"
import UserItems from "@/components/normal/Users/items"
import { projectListApi } from "@/redux/projects/services"
import { useAppSelector } from "@/redux"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { userListApi } from "@/redux/user/services"
import { userSelectors } from "@/redux/user"

interface IProjectsPageProps {}

const UsersList: React.FunctionComponent<IProjectsPageProps> = (props) => {
  const [search, setSearch] = useSearchParams()

  const { t } = useTranslation("routes")
  const dispatch = useDispatch()

  const users = useAppSelector(userSelectors.usersListSelector)

  const searchParameter = search.get("s")

  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setLoading(true)
    dispatch(
      userListApi(
        {
          limit: 1000,
          skip: 0,
          ...((searchParameter && {
            s: searchParameter
          }) ||
            {})
        },
        () => {
          //onSuccess state
          setLoading(false)
        },
        () => {
          //onError state
          setLoading(false)
        }
      )
    )
  }, [searchParameter])

  return (
    <PageWrapper title={t("projects")}>
      <HeaderProjectToolbar />
      <Box sx={{ height: 24 }}></Box>
      {loading ? <ProjectSkeletonItems /> : <UserItems items={users} />}
    </PageWrapper>
  )
}

export default UsersList
