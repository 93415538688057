import * as React from "react"

import { SxProps, TextField, Theme } from "@mui/material"

interface IPureInputProps {
  placeholder?: string
  onChange?: (val: string) => void
  onEnter?: (val: string) => void
  sx?: SxProps<Theme>
  defaultVal?: string
}

const PureInput: React.FunctionComponent<IPureInputProps> = ({
  placeholder = "",
  onChange,
  onEnter,
  sx,
  defaultVal = ""
}) => {
  const [val, setVal] = React.useState(defaultVal)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setVal(newValue)
    if (onChange && typeof onChange === "function") {
      onChange(newValue)
    }
  }

  const clickOnSearch = () => {
    if (onEnter && typeof onEnter === "function") {
      onEnter(val)
    }
  }

  const handleKeyUp = (data: any) => {
    if (data.keyCode === 13) {
      clickOnSearch()
    }
  }

  //Checking for chaning default val
  React.useEffect(() => {
    //Setting our state val when default changed
    setVal(defaultVal)
  }, [defaultVal])

  return (
    <TextField
      placeholder={placeholder}
      onKeyUp={handleKeyUp}
      value={val}
      onChange={handleChange}
      sx={sx}
    />
  )
}

export default React.memo(PureInput)
