import * as React from "react"

import { Box, Breakpoint, Container, SxProps, Theme } from "@mui/material"

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import PageHeading from "./partials/heading"
import { useLocation } from "react-router"

interface IPageWrapperProps {
  title: string
  onClose?: () => void
  metaData?: React.ReactElement
  hasContainer?: boolean
  container?: Breakpoint
  sx?: SxProps<Theme>
  bgcolor?: string
}

const PageWrapper: React.FunctionComponent<IPageWrapperProps> = (props) => {
  const {
    title,
    children,
    hasContainer = true,
    container = "xl",
    sx,
    bgcolor
  } = props

  // document.title = title

  const { pathname } = useLocation()

  return (
    <Box
      sx={{
        bgcolor: (t) =>
          pathname === "/dashboard/projects"
            ? t.palette.grey[100]
            : t.palette.common.white,
        minHeight: "100vh"
      }}
    >
      {/** Content */}
      <Box sx={sx}>
        {hasContainer ? (
          <Container maxWidth="xl">{children}</Container>
        ) : (
          children
        )}
      </Box>
      {/** Content */}
    </Box>
  )
}

export default PageWrapper
