import * as actionTypes from "./actions"

import { Reducer } from "redux"

type StateType = {
  userListLoading: boolean
  users: any[]
  user: any
  userInputs: any
  layoutView: "grid" | "list"
  countries: any[]
}

const initial: StateType = {
  userListLoading: true,
  users: [],
  userInputs: [],
  user: {},
  layoutView: "grid",
  countries: []
}

const reducer: Reducer<StateType> = (state = initial, action) => {
  switch (action.type) {
    case actionTypes.USER_LIST_LOADING:
      return {
        ...state,
        userListLoading: true
      }
    case actionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action?.payload
      }
    case actionTypes.USER_LIST_SUCCESS:
      return {
        ...state,
        userListLoading: false,
        users: action.payload
      }
    case actionTypes.USER_LIST_FAILURE:
      return {
        ...state,
        userListLoading: false,
        users: []
      }
    case actionTypes.SET_USER_LOCATION:
      return {
        ...state,
        user: { ...state.user, location: action.payload }
      }
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case actionTypes.SET_USER_INPUTS:
      return {
        ...state,
        userInputs: action.payload
      }
    case actionTypes.SET_LAYOUT_VIEW:
      return {
        ...state,
        layoutView: action.payload
      }
    default:
      return state
  }
}

export default reducer
