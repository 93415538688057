import { authReducer } from "./auth"
import { projectsReducer } from "./projects"
import { userReducer } from "./user"
import { combineReducers } from "redux"
import { manualReducer } from "./manualdata"

const rootReducer = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  users:userReducer,
  manualData:manualReducer
})

export default rootReducer
/* will add persist configs here */
