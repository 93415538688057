import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha
} from "@mui/material"

import { Link } from "react-router-dom"
import { ListItemWithIconProps } from "./interface"

export const ListItemWithIcon: React.FC<ListItemWithIconProps> = ({
  title,
  activeIcon,
  icon,
  href,
  isActive = false,
  onClick
}) => {
  const handleClick = () => {
    if (onClick) onClick()
  }

  const Inner = (
    <ListItemButton onClick={handleClick} sx={{ borderRadius: 2 }}>
      {icon && (
        <ListItemIcon
          className={isActive ? "active" : ""}
          sx={{ minWidth: "32px !important" }}
        >
          {isActive ? activeIcon : icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={title}
        sx={{
          color: (t) =>
            isActive
              ? t.palette.primary.main
              : alpha(t.palette.common.black, 0.6)
        }}
      />
    </ListItemButton>
  )

  return (
    <ListItem
      disablePadding
      sx={{
        [`& a`]: {
          width: "100%"
        }
      }}
    >
      {href ? (
        <Link to={href}>
          <Box
            sx={{
              color: (t) => alpha(t.palette.common.black, 0.6),
              width: "100%"
            }}
          >
            {Inner}
          </Box>
        </Link>
      ) : (
        Inner
      )}
    </ListItem>
  )
}
