import * as React from "react"

import { Box, alpha } from "@mui/material"

interface IPurchaseStatusProps {
  status: string
}

const PurchaseStatus: React.FunctionComponent<IPurchaseStatusProps> = ({
  status
}) => {
  return (
    <Box
      sx={{
        py: 0.5,
        px: 1,
        borderRadius: 2,
        bgcolor: (t) =>
          status === "Paid"
            ? alpha(t.palette.success.main, 0.12)
            : alpha(t.palette.warning.main, 0.12),
        color: (t) =>
          status === "Paid" ? t.palette.success.main : t.palette.warning.main
      }}
    >
      {status}
    </Box>
  )
}

export default PurchaseStatus
