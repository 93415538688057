import * as React from "react"

import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Skeleton,
  Stack,
  Typography
} from "@mui/material"
import { api, getEndpoint } from "@/api"

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { IProfile } from "@/types/profile"
import { ListItemWithIcon } from "@/components/reusables/ListItemWithicon"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import { ModalGravity } from "@/components/reusables/modal/interface"
import PropmptBox from "@/components/reusables/propmt-box"
import UserEditPassword from "./edit-password"
import UserEditProfile from "./edit-profile"
import UserPurchasesProfile from "./purchases"
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined"
import { authLogout } from "@/redux/auth/actions"
import { authSelectors } from "@/redux/selectors"
import { getFileUrl } from "@/utils/file"
import { toaster } from "@/components/reusables/toaster"
import { useAppSelector } from "@/redux"
import { useDispatch } from "react-redux"
import { useGlobalContext } from "@/contexts/g-context"
import { useTranslation } from "react-i18next"

interface IUserProfileProps {}

const UserProfile: React.FunctionComponent<IUserProfileProps> = (props) => {
  const currentUser = useAppSelector(authSelectors.userSelector)
  const dispatch = useDispatch()

  const { t } = useTranslation(["common", "sections"])

  const { showModal, closeModal } = useGlobalContext()

  const changeLayout = (
    component: React.ReactNode,
    gravity: ModalGravity = "modal"
  ) => {
    closeModal()
    showModal(component, gravity)
  }

  const backToProfile = () => {
    closeModal()
    showModal(<UserProfile />)
  }

  const logout = () => {
    closeModal()
    dispatch(authLogout())
  }

  const [isUploading, setUploading] = React.useState(false)

  //Get profile
  const [profile, setProfile] = React.useState<IProfile | null>(null)
  const getProfile = () => {
    api
      .get(getEndpoint("profile"))
      .then((res) => {
        setProfile(res.data?.user)
      })
      .catch((err) => {
        //Toast error
        toaster.error(err)
      })
  }

  //Getting profile
  React.useEffect(() => {
    getProfile()
    return () => {
      setProfile(null)
    }
  }, [])

  const profileImage = (profile && getFileUrl(profile.profile_picture)) || null

  return (
    <Stack direction="column">
      <IconButton
        onClick={() => closeModal()}
        sx={{ position: "absolute", left: 8, top: 8 }}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ mt: 6, mb: 4 }}
      >
        <Box>
          {isUploading ? (
            <Skeleton
              sx={{
                width: 64,
                height: 64,
                borderRadius: "50%",
                transform: "none"
              }}
            />
          ) : (
            <Button
              variant="text"
              component="label"
              sx={{
                border: 0,
                backgroundColor: "transparent !important",
                py: "0px !important"
              }}
              disableTouchRipple
              disableRipple
            >
              <Avatar
                {...((profileImage && {
                  src: profileImage
                }) ||
                  {})}
                sx={{ width: 64, height: 64 }}
              />
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const formData = new FormData()
                  if (e.target.files)
                    formData.append("image", e.target.files[0])
                  setUploading(true)
                  api
                    .post(getEndpoint("uploadProfileImage"), formData)
                    .then((res) => {
                      setUploading(false)
                      //Fetch data again
                      getProfile()
                    })
                    .catch((err) => {
                      setUploading(false)
                      toaster.error(err)
                    })
                }}
              />
            </Button>
          )}
        </Box>

        <Typography component="strong" sx={{ fontSize: 22 }}>
          {currentUser?.fullName}
        </Typography>
      </Stack>
      <List>
        <ListItemWithIcon
          title={t("common:edit_profile")}
          icon={<EditOutlinedIcon />}
          onClick={() => changeLayout(<UserEditProfile />)}
        />
        <ListItemWithIcon
          title={t("common:change_password")}
          icon={<VpnKeyOutlinedIcon />}
          onClick={() => changeLayout(<UserEditPassword />)}
        />
        <ListItemWithIcon
          title={t("common:purchases_history")}
          icon={<CreditScoreOutlinedIcon />}
          onClick={() => changeLayout(<UserPurchasesProfile />)}
        />
        <Divider />
        <ListItemWithIcon
          title={t("common:log_out")}
          icon={<LogoutOutlinedIcon />}
          onClick={() =>
            changeLayout(
              <PropmptBox
                onSubmit={logout}
                onClose={backToProfile}
                title={t("common:log_out")}
                description={t("sections:are_you_sure_you_want_to_log_out")}
              />,
              "bottom"
            )
          }
        />
      </List>
    </Stack>
  )
}

export default UserProfile
