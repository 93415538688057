import * as React from "react"

import { Box, CircularProgress, IconButton } from "@mui/material"

import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import SearchIcon from "@mui/icons-material/Search"

interface ISearchBoxProps {
  searchInput: React.ReactNode
  onClick?: () => void
  loading?: boolean
  hasText?: boolean
  icon?: React.ReactNode
}

const SearchBox: React.FunctionComponent<ISearchBoxProps> = ({
  searchInput,
  onClick,
  loading = false,
  hasText = false,
  icon = <SearchIcon />
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      {searchInput}
      <IconButton
        onClick={onClick}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)"
        }}
      >
        {loading ? (
          <CircularProgress
            sx={{ width: "24px !important", height: "24px !important" }}
          />
        ) : hasText ? (
          <CloseRoundedIcon />
        ) : (
          icon
        )}
      </IconButton>
    </Box>
  )
}

export default SearchBox
