import { StateType, store } from "@/redux"
import axios, { AxiosResponse } from "axios"

import { authLogout } from "./../redux/auth/actions"
import routes from "@/navigation/routeNames"
import { toaster } from "@/components/reusables/toaster"

let CANCEL_TOKEN_SOURCE = axios.CancelToken.source()

function generateNewCancelTokenSource() {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source()
}

const tokenSelector = (state: StateType): string | null => {
  return state.auth.authToken
}

/**
 * axios instance and interceptors are created here.
 * Will subscribe to redux store later on.
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    Accept: "application/json, text/plain, */*",
    Authorization: `${tokenSelector(store.getState())}`
  }
})

api.interceptors.response.use(
  (response) => {
    if (response) {
      //perform the manipulation here and change the response object
    }
    return response
  },
  (error) => {
    //Access denied status
    if (error?.response?.status === 403) {
      store.dispatch(authLogout())
      setTimeout(() => {
        window.location.href = routes.login
      }, 100)
    }
    return Promise.reject(error.response.data)
  }
)

store.subscribe(() => {
  const headers = api.defaults.headers as any
  headers["Authorization"] = tokenSelector(store.getState())
})

export default api
