import * as React from "react"

import { Button, Stack, Typography } from "@mui/material"

import { LoadingButton } from "@mui/lab"
import { useTranslation } from "react-i18next"

interface IPropmptBoxProps {
  title: string
  description?: string
  onSubmit: () => void
  onClose: () => void
  cancelButtonText?: string
  acceptButtonText?: string
  loadingSubmit?: boolean
}

const PropmptBox: React.FunctionComponent<IPropmptBoxProps> = ({
  title,
  description,
  onSubmit,
  onClose,
  cancelButtonText,
  acceptButtonText,
  loadingSubmit = false
}) => {
  const { t } = useTranslation("common")

  return (
    <Stack direction="column" sx={{ p: 2 }} spacing={3}>
      <Typography component="strong" variant="h6">
        {title}
      </Typography>
      {description && <Typography component="span">{description}</Typography>}
      <Stack direction="row" spacing={1}>
        <Button onClick={onClose} variant="outlined" color="error">
          {cancelButtonText || t("cancel")}
        </Button>
        <LoadingButton
          loading={loadingSubmit}
          onClick={onSubmit}
          variant="contained"
          color="error"
          sx={{ width: 120, maxWidth: "100%" }}
        >
          {acceptButtonText || t("submit")}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default PropmptBox
