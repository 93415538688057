import * as actionTypes from "./actions"

import { Reducer } from "redux"
import _ from "lodash"

type StateType = {
  passiveData: any
  passivePcpData: any
  activePcpData: any
  offsetPcpData: any
}

const initial: StateType = {
  passiveData: {},
  passivePcpData: [],
  activePcpData: [],
  offsetPcpData: []
}

const reducer: Reducer<StateType> = (state = initial, action) => {
  switch (action.type) {
    case actionTypes.SET_PASSIVE_DATA:
      const { parent, values } = action.payload
      let key = parent[0].split("-")[0]
      let heading = parent[0].split("-")[1]
      let tempPassiveData = { ...state.passiveData }

      tempPassiveData[key] = {
        ...tempPassiveData[key],
        [heading]: values
      }

      return {
        ...state,
        passiveData: tempPassiveData
      }
    case actionTypes.DELETE_PASSIVE_VALUE:
      const { level1, child, value } = action.payload

      let passiveData = { ...state.passiveData }

      passiveData[level1] = {
        ...passiveData[level1],
        [child]: passiveData[level1][child].filter((x) => x.value !== value)
      }

      let tempArray = []
      let keys = Object.keys(passiveData)
      for (let i = 0; i < keys.length; i++) {
        for (const key in passiveData[keys[i]]) {
          const element = passiveData[keys[i]][key]
          if (element.length === 0) {
            delete passiveData[keys[i]][key]
          }
        }
      }

      return {
        ...state,
        passiveData: passiveData
      }

    case actionTypes.CLEAR_PASSIVE_VALUE:
      return {
        ...state,
        passiveData: {}
      }
    case actionTypes.SET_PASSIVE_PCP_DATA:
      return {
        ...state,
        passivePcpData: action.payload
      }

    case actionTypes.SET_ACTIVE_PCP_DATA:
      return {
        ...state,
        activePcpData: action.payload
      }

    case actionTypes.SET_OFFSET_PCP_DATA:
      return {
        ...state,
        offsetPcpData: action.payload
      }
    case actionTypes.SET_PASSIVE_DATA_EXISTING:
      let data = _.cloneDeep(action.payload)
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          for (const subkey in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], subkey)) {
              data[key][subkey] = data[key][subkey].map((x) => ({
                text: x,
                value: x
              }))
            }
          }
        }
      }

      return {
        ...state,
        passiveData: data
      }
    default:
      return state
  }
}

export default reducer
