import * as React from "react"

import { Box, Button, Divider, Grid, Stack, alpha } from "@mui/material"
import { IPurchase, PurchaseTitleVal } from "./card"

import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ProfileItemsHeading from "../profile-items-heading"
import UserPurchasesProfile from "."
import humanDate from "@/utils/humanDate"
import { useGlobalContext } from "@/contexts/g-context"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface IPurchaseCardViewProps {
  item: IPurchase
}

const PurchaseCardView: React.FunctionComponent<IPurchaseCardViewProps> = ({
  item
}) => {
  //Getting translation object
  const { t } = useTranslation(["common"])

  //Getting navigate
  const navigate = useNavigate()

  //Gettinng global context
  const { closeModal } = useGlobalContext()

  //Navigate to project
  const navigateToProject = () => {
    closeModal()
    navigate(`/dashboard/projects/show/${item._id}/location`)
  }

  return (
    <Stack direction="column" spacing={1}>
      <ProfileItemsHeading
        title={item.name}
        hasBorder={false}
        backComponent={<UserPurchasesProfile />}
      />
      <Box
        sx={{
          height: 8,
          width: "100%",
          bgcolor: (t) => alpha(t.palette.common.black, 0.04)
        }}
      ></Box>
      <Stack spacing={4} sx={{ py: 2, px: 3 }}>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12}>
              <PurchaseTitleVal
                direction="column"
                justifyContent="space-between"
                title={t("common:purchase_id")}
                val={item._id}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <PurchaseTitleVal
                title={t("common:date")}
                val={humanDate(item.createdAt)}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <PurchaseTitleVal title={t("common:billed_to")} val={"-"} />
            </Grid>
            <Grid item xs={12} lg={12}>
              <PurchaseTitleVal
                direction="row"
                justifyContent="space-between"
                title={t("common:fee")}
                val={`${(item?.price || 0).withCommas().withPriceUnit()}`}
              />
              <PurchaseTitleVal
                direction="row"
                justifyContent="space-between"
                title={t("common:discount_amount")}
                val={`${item.discountPercentage}%`}
              />
              <PurchaseTitleVal
                direction="row"
                justifyContent="space-between"
                title={t("common:discount_code")}
                val={item?.discountCode || "-"}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <PurchaseTitleVal
          direction="row"
          justifyContent="space-between"
          title={t("common:total_paid")}
          val={item.paymentAmount.withCommas().withPriceUnit()}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="outlined"
            sx={{
              fontWeight: "bold",
              color: (t) => t.palette.primary.main
            }}
            color="primary"
            onClick={navigateToProject}
            endIcon={<ArrowForwardIcon />}
          >
            {t("common:go_to_project")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default PurchaseCardView
