import * as React from "react";

import { Button, IconButton, Stack, Typography, alpha } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import Logo from "@/components/normal/logo";
import PureInput from "@/components/reusables/search/input";
import SearchBox from "@/components/reusables/search/SrachBox";
import UserProfile from "@/components/normal/profile";
import actions from "@/redux/projects/actions";
import { authSelectors } from "@/redux/auth";
import { layoutViewSelector } from "@/redux/projects/selectors";
import { useAppSelector } from "@/redux";
import { useDispatch } from "react-redux";
import { useGlobalContext } from "@/contexts/g-context";
import { useTranslation } from "react-i18next";

interface IHeaderProjectsToolbarProps {}

const HeaderProjectsToolbar: React.FunctionComponent<
  IHeaderProjectsToolbarProps
> = (props) => {
  const [search, setSearch] = useSearchParams();
  const searchParameter = search.get("s");

  const router = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation(["common", "sections"]);
  const currentUser = useAppSelector(authSelectors.userSelector);
  const currentLayoutView = useAppSelector(layoutViewSelector);

  const { showModal, closeModal } = useGlobalContext();

  const dispatch = useDispatch();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        py: 2,
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Logo />
        {!(pathname === "/admin-dashboard") && (
          <SearchBox
            searchInput={
              <PureInput
                sx={{ width: 600 }}
                placeholder={t("common:search_in_list")}
                onEnter={(val) => router(`?s=${val}`)}
                defaultVal={searchParameter || ""}
              />
            }
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        {!(pathname === "/admin-dashboard") && !(pathname === "/users") && (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              p: 1,
              bgcolor: (t) => t.palette.common.white,
              borderRadius: "8px",
            }}
          >
            {[
              { icon: <GridViewOutlinedIcon />, value: "grid" },
              {
                icon: <FormatListBulletedOutlinedIcon />,
                value: "list",
              },
            ].map((item, key) => {
              const isActive = Boolean(currentLayoutView === item.value);
              return (
                <IconButton
                  sx={{
                    ...(isActive && {
                      color: (t) => t.palette.primary.main,
                      bgcolor: (t) => alpha(t.palette.primary.main, 0.05),
                    }),
                  }}
                  key={key}
                  onClick={() => {
                    dispatch(actions.setLayoutView(item.value));
                  }}
                >
                  {item.icon}
                </IconButton>
              );
            })}
          </Stack>
        )}
        {!(pathname === "/admin-dashboard")&& !(pathname === "/users") &&  (
          <a href={process.env.REACT_APP_ARENPRO_WEBSITE} target="_blank">
            <Button
              variant="outlined"
              color="primary"
              sx={{
                color: (t) => t.palette.primary.main,
                borderColor: (t) => t.palette.primary.main,
                minHeight: 56,
              }}
            >
              {t("common:do_you_need_help")}
            </Button>
          </a>
        )}

        <Stack
          direction="row"
          sx={{
            py: 1,
            px: 2,
            bgcolor: (t) => t.palette.common.white,
            borderRadius: 2,
            cursor: "pointer",
          }}
          spacing={1}
          alignItems="center"
          component="div"
          onClick={() => showModal(<UserProfile />)}
        >
          <AccountCircleOutlinedIcon />
          <Typography component="strong">{currentUser.fullName}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HeaderProjectsToolbar;
