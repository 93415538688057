import * as React from "react"
import * as Yup from "yup"

import {
  Box,
  Button,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme
} from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { adminAuthLogin, authLogin, authRegister } from "@/redux/auth/services"

import { LoadingButton } from "@mui/lab"

import LoginWithSocials from "@/components/normal/login-with-socials"
import Logo from "@/components/normal/logo"
import MiniContainer from "@/containers/holders/miniContainer"
import PageWrapper from "@/containers/page-wrapper"
import { routeNames } from "@/navigation"
import { toaster } from "@/components/reusables/toaster"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

interface ILoginPageProps {}

interface IFormikValues {
  email: string
  password: string
}

const SuperAdminLoginPage: React.FunctionComponent<ILoginPageProps> = () => {
  const theme = useTheme<Theme>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { t } = useTranslation(["inputValidations", "common"])

  const { values, isSubmitting, errors, touched, handleSubmit, getFieldProps } =
    useFormik<IFormikValues>({
      validationSchema: Yup.object().shape({
        email: Yup.string().required(
          t("inputValidations:this_field_is_required")
        ),
        password: Yup.string().required(
          t("inputValidations:this_field_is_required")
        )
      }),
      initialValues: {
        email: "",
        password: ""
      },
      onSubmit: (values, actions) => {
        actions.setSubmitting(true)
        dispatch(
          adminAuthLogin(
            values,
            actions.setSubmitting,
            ({data}) => {

              const {user}=data
              setTimeout(() => {
                actions.setSubmitting(false)
                toaster.success(t("common:you_logged_in_succesfully"))
                if(user.role==="super-admin")
                navigate("/admin-dashboard")
                else
                navigate("/dashboard/projects")

              }, 100)
            },
            (e) => {
              actions.setSubmitting(false)
              toaster.error(e)
            }
          )
        )
      }
    })

  return (
    <PageWrapper title={t("common:login")} bgcolor={theme.palette.common.white}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 3 }}
      >
        <Logo />
        
      </Stack>
      <MiniContainer width={400}>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography component="h1" variant="h6" sx={{ mb: 2 }}>
            {t("common:login")}
          </Typography>
         
      
          <Stack direction="column" spacing={2}>
            <TextField
              {...getFieldProps("email")}
              label={t("common:email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              {...getFieldProps("password")}
              label={t("common:password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              type="password"
            />
          
          </Stack>
          <LoadingButton
            loading={isSubmitting}
            sx={{ mt: 8 }}
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
          >
            {t("common:login")}
          </LoadingButton>
        </Box>
      </MiniContainer>
    </PageWrapper>
  )
}

export default SuperAdminLoginPage
