import * as React from "react"

import { IconButton, Stack, Typography, alpha } from "@mui/material"

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import UserProfile from "."
import { useGlobalContext } from "@/contexts/g-context"

interface IProfileItemsHeadingProps {
  title: string
  hasBorder?: boolean
  backComponent?: any
}

const ProfileItemsHeading: React.FunctionComponent<
  IProfileItemsHeadingProps
> = ({ title, hasBorder = true, backComponent = <UserProfile /> }) => {
  const { showModal, closeModal } = useGlobalContext()

  const backToProfile = () => {
    closeModal()
    showModal(backComponent)
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        p: 2,
        borderBottom: hasBorder ? 1 : 0,
        borderColor: (t) =>
          hasBorder ? alpha(t.palette.common.black, 0.12) : "transparent"
      }}
    >
      <IconButton onClick={backToProfile}>
        <CloseOutlinedIcon />
      </IconButton>
      <Typography
        component="strong"
        variant="h6"
        sx={{ position: "relative", top: 2 }}
      >
        {title}
      </Typography>
    </Stack>
  )
}

export default ProfileItemsHeading
