import { StateType } from "@/redux/createStore"
import { createSelector } from "reselect"

export const passiveDataSelector = createSelector(
  (state: StateType) => state.manualData,
  ( manualData) => manualData.passiveData
)
export const passivePcpDataSelector = createSelector(
  (state: StateType) => state.manualData,
  (manualData) => manualData.passivePcpData
)

export const activePcpDataSelector = createSelector(
  (state: StateType) => state.manualData,
  (manualData) => manualData.activePcpData
)

export const offsetPcpDataSelector = createSelector(
  (state: StateType) => state.manualData,
  (manualData) => manualData.offsetPcpData
)



