import * as actionTypes from "./actions"

import { Reducer } from "redux"

type StateType = {
  projectListLoading: boolean
  projects: any[]
  projectLoading: boolean
  projectsTotalCount: number
  project: any
  tempCoords: any
  userInputs: any
  manualInputs: any
  passivePcpData: any
  layoutView: "grid" | "list"
}

const initial: StateType = {
  projectListLoading: true,
  projectLoading: true,
  projects: [],
  tempCoords: null,
  projectsTotalCount: 0,
  userInputs: [],
  project: {},
  manualInputs: {},
  passivePcpData: [],
  layoutView: "grid"
}

const reducer: Reducer<StateType> = (state = initial, action) => {
  switch (action.type) {
    case actionTypes.PROJECT_LIST_LOADING:
      return {
        ...state,
        projectListLoading: true
      }
    case actionTypes.PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectListLoading: false,
        projects: action.payload?.items,
        projectsTotalCount: action.payload?.totalCount,
        project: {},
        tempCoords: null
      }
    case actionTypes.PROJECT_LIST_FAILURE:
      return {
        ...state,
        projectListLoading: false,
        projects: []
      }
    case actionTypes.SET_PROJECT_LOCATION:
      return {
        ...state,
        project: { ...state.project, location: action.payload }
      }
    case actionTypes.SET_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload
        },
        projectLoading: false
      }
    case actionTypes.SET_PROJECT_NEIGHBOURS:
      return {
        ...state,
        project: {
          ...state.project,
          neighbours: action.payload
        }
      }
    case actionTypes.SET_PROJECT_NEIGHBOURS_DATA:
      return {
        ...state,
        project: {
          ...state.project,
          neighbour: action.payload
        }
      }
    case actionTypes.REMOVE_PROJECT_NEIGHBOURS:
      return {
        ...state,
        project: {
          ...state.project,
          neighbours: state.project?.neighbours?.filter(
            (item: any, index: number) => index !== action?.payload
          )
        }
      }
    case actionTypes.SET_USER_INPUTS:
      return {
        ...state,
        userInputs: action.payload
      }
    case actionTypes.SET_MANUAL_INPUTS:
      return {
        ...state,
        manualInputs: action.payload
      }

    case actionTypes.SET_LAYOUT_VIEW:
      return {
        ...state,
        layoutView: action.payload
      }
    case actionTypes.SET_TEMP_COORDS:
      return {
        ...state,
        tempCoords: action.payload
      }
    default:
      return state
  }
}

export default reducer
