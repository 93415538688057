/**
 * All helper functions (if any) are placed here
 */
export { default as numberToTimeFormat } from "./numberToTimeFormat"
export { default as getPaths } from "./getPaths"
export { default as addPrefix } from "./addPrefix"
export { default as mutableObj } from "./mutableObj"
export { default as calcPageCount } from "./calcPageCount"

export const selectedFilter = (vals: any[] | undefined | null) => {
  try {
    return `${
      vals && vals instanceof Array
        ? vals.filter((v: any) => !!v.default)[0].value
        : ""
    }`
  } catch (e) {}
}

export const camelcaseSpace = (str: String) =>
  str
    .replace(/([A-Z])/g, " $1")
    // uppercase the first character
    .replace(/^./, function (str) {
      return str.toUpperCase()
    })
