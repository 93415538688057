import * as React from "react";
import * as moment from "jalali-moment";

import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import Chip from '@mui/material/Chip';

import AddIcon from "@mui/icons-material/Add";
import AddUser from "./add-user";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MyTable from "@/components/reusables/table/ny-table";
import humanDate from "@/utils/humanDate";
import { layoutViewSelector } from "@/redux/projects/selectors";
import { useAppSelector } from "@/redux";
import { useGlobalContext } from "@/contexts/g-context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { userDeleteApi, userListApi } from "@/redux/user/services";

export interface User {
  fullName: string;
  companyName: string;
  companySize: string;
  email: string;
  role:string;
  password?: string;
  isDeleted?: boolean;
  _id: string;
}
interface IProjectItemsProps {
  items?: User[];
}

const UserItems: React.FunctionComponent<IProjectItemsProps> = ({
  items = [],
}) => {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
const dispatch=useDispatch()
  const { showModal, closeModal } = useGlobalContext();

  const currentLayoutView = useAppSelector(layoutViewSelector);

  const isGrid = currentLayoutView === "grid";

  const RenderContent = () => {
    return (
      <Stack
        spacing={1}
        sx={{ bgcolor: (t) => t.palette.common.white, borderRadius: "8px" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 2, px: 3 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            {/* {t("projects_list")} */}
            User List
          </Typography>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={() => showModal(<AddUser closeModal={closeModal} />)}
          >
            {/* {t("new_project")} */}
            New User
          </Button>
        </Stack>
        <MyTable
          items={items}
          tableHeadItems={[
            {
              title: "Full Name",
              key: "fullName",
              render: (item: User) => {
                return item?.fullName;
              },
            },
            {
              title: "Role",
              key: "role",
              render: (item: User) => {
                return item?.role;
              },
            },
            {
              title: "Company Name",
              key: "companyName",
              render: (item: User) => {
                return item?.companyName;
              },
            },
            
            {
              title: "Company Size",
              key: "companySize",
              render: (item: User) => {
                return item?.companySize;
              },
            },
            {
              title: "Active Status",
              render: (item: User) => {
                return <Chip label={item?.isDeleted?"Deleted":"Active"} color={item?.isDeleted?"error":"success"} />;
              },
            },

            {
              title: "",
              render: (item: any) => {
                return (
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        showModal(
                          <AddUser user={item} closeModal={closeModal} />
                        );
                      }}
                    >
                      <ModeEditOutlinedIcon />
                    </IconButton>
                   {!item.isDeleted &&  <IconButton aria-label="delete" size="large" >
                      <DeleteIcon onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          userDeleteApi(
                            
                            (res) => {
                              closeModal()
                              dispatch(
                                userListApi({
                                  limit: 1000,
                                  skip: 0
                                })
                              )
                            },
                            console.log,
                            item
                          )
                        )
                      }} />
                    </IconButton>}
                  </Stack>
                );
              },
            },
          ]}
        />
      </Stack>
    );
  };

  return <RenderContent />;
};

export default UserItems;
