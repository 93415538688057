import moment from "jalali-moment"
import { t } from "i18next"

const humanDate = (
  date: any,
  timeformat: string = `YYYY/MM/DD`,
  locale: any = "en"
) => moment(date).format(timeformat)

export default humanDate
