/**
 * Place all endpoints here
 */

type ParamsType = string | number | boolean
type NoParamKeys =
  | "auth-register"
  | "auth-register"
  | "auth-login"
  | "admin-auth-login"
  | "auth-send-email"
  | "auth-verify-email"
  | "auth-otp-step-two"
  | "projects"
  | "changePassword"
  | "resetPassword"
  | "profile"
  | "uploadProfileImage"
  | "newPassword"
  | "userInput"
  | "users"
  | "userCreate"
  | "purchase-history"
  | "epwSearch"

type ParamKeys =
  | "projectView"
  | "projectPartial"
  | "promosView"
  | "fileView"
  | "pricing"
  | "parametric1"
  | "parametric2"
  | "parametric3"
  | "cms"
  | "userEdit"
  | "userDelete"
  | "projectDelete"

type EndpointsType = {
  [k in NoParamKeys]: {
    endpoint: string
  }
} & {
  [k in ParamKeys]: {
    endpoint: string
    params: string[]
  }
}

type ParamsGenerator<T extends ParamKeys> = {
  [k in typeof endpoints[T]["params"][number]]: ParamsType
}

type FunctionOverload = {
  <T extends NoParamKeys>(key: T): string
  <T extends ParamKeys>(key: T, params: ParamsGenerator<T>): string
}

/**
 * endpoints must have the following naming
 * @example ```js
 * const endpoints = {
 *    "endpoint-key": {
 *      endpoint: "path/to/my/endpoint/" or "path/to/:my/endpoint/:params",
 *      params: false or ["my", "params"]
 *    }
 * }
 * This way typescript will suggest you with required params
 * ```
 */
export const endpoints = {
  "auth-register": {
    endpoint: "/auth/register"
  },
  "purchase-history": {
    endpoint: "/projects/user/purchase/history"
  },
  "auth-login": {
    endpoint: "/auth/login"
  },
  "social-auth": {
    endpoint: "/social-auth",
  },
  "admin-auth-login": {
    endpoint: "/admin/auth/login"
  },
  uploadProfileImage: {
    endpoint: "/upload/profile/image"
  },
  "auth-send-email": {
    endpoint: "/send/email"
  },
  "auth-verify-email": {
    endpoint: "/verify/email"
  },
  "auth-otp-step-two": {
    endpoint: "/auth/login/via-otp/step-one"
  },
  profile: {
    endpoint: "/profile"
  },
  epwSearch: {
    endpoint: "/epw/search"
  },
  //Projects
  projects: {
    endpoint: "/projects"
  },
  projectView: {
    endpoint: "/projects/:id",
    params: ["id"]
  },
  pricing: {
    endpoint: "/projects/:id/pricing",
    params: ["id"]
  },
  parametric1: {
    endpoint: "/projects/:id/parametric/step1",
    params: ["id"]
  },
  parametric2: {
    endpoint: "/projects/:id/parametric/step2",
    params: ["id"]
  },
  parametric3: {
    endpoint: "/projects/:id/parametric/step3",
    params: ["id"]
  },
  fileView: {
    endpoint: "/file/:id",
    params: ["id"]
  },
  promosView: {
    endpoint: "/promos/:copoun_id",
    params: ["copoun_id"]
  },
  projectPartial: {
    endpoint: "/projects/:id/:type",
    params: ["id", "type"]
  },

  projectVertices: {
    endpoint: "/projects/:id/:vertices",
    params: ["id", "vertices"]
  },

  changePassword: {
    endpoint: "/change/password"
  },
  resetPassword: {
    endpoint: "/forgot/password"
  },
  newPassword: {
    endpoint: "/new/password"
  },
  userInput: {
    endpoint: "/cms/user-inputs"
  },
  cms: {
    endpoint: "/cms/:slug",
    params: ["slug"]
  },
  // USERS
  users: {
    endpoint: "/admin/users"
  },
  userCreate: {
    endpoint: "/admin/user/create"
  },
  userEdit: {
    endpoint: "/admin/user/update/:id",
    params: ["id"]
  },
  userDelete: {
    endpoint: "/admin/user/delete/:id",
    params: ["id"]
  },
  projectDelete: {
    endpoint: "/projects/:id",
    params: ["id"]
  }
} as const

/**
 * this function returns an endpoint based on panel, key, and params
 * @param key endpoint key inside that panel
 * @param params if the selected endpoint had any params(for example /details/:id), the keys in this argument must match the params of that endpoint({ id: 2 } in this example)
 * @returns the endpoint of the API
 */
const getEndpoint: FunctionOverload = (...args: any[]) => {
  const key = args[0] as keyof typeof endpoints
  const params: any = args?.[1]
  let selectedEndpoint: string = endpoints[key].endpoint
  if (params) {
    for (let key in params) {
      let regex = new RegExp(`\/:${key}`)
      selectedEndpoint = selectedEndpoint.replace(regex, "/" + params[key])
    }
  }
  return selectedEndpoint
}

export default getEndpoint
