import * as React from "react"

import { ASSETS_PATH } from "@/consistants"
import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import { useAppSelector } from "@/redux"
import { authSelectors } from "@/redux/auth"

interface ILogoProps {}

const Logo: React.FunctionComponent<ILogoProps> = (props) => {

  const user = useAppSelector(authSelectors.userSelector)

  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: (t) => t.palette.common.white,
        borderRadius: 2,
        overflow: "hidden",
        [`& a`]: {
          display: "flex",
          alignItems: "center",
          [`& img`]: {
            mx: 2
          }
        }
      }}
    >
      <Link to={`${user?.role==="super-admin"?"/admin-dashboard":"/"}`}>
        <img
          width="120px"
          src={`${ASSETS_PATH}/logo/main-logo-header.svg`}
          alt="Logo"
        />
      </Link>
    </Box>
  )
}

export default Logo
