import * as React from "react"
import * as Yup from "yup"

import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { projectListApi, projectsCreateApi } from "@/redux/projects/services"

import { LoadingButton } from "@mui/lab"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { User } from "./items"
import { UserCreateType } from "@/redux/user/types"
import { userCreatApi, userListApi } from "@/redux/user/services"

interface IAddUserProps {
  closeModal: () => void
  user?: User
}

const AddUser: React.FunctionComponent<IAddUserProps> = ({
  closeModal,
  user
}) => {
  const isEdit = Boolean(user)

  const { t } = useTranslation(["common", "inputValidations", "options"])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { values, isSubmitting, errors, touched, handleSubmit, getFieldProps } =
    useFormik<UserCreateType>({
      validationSchema: Yup.object().shape({
       
        fullName: Yup.string().required(
          t("inputValidations:this_field_is_required")
        ),
        email: Yup.string().email().required(
          t("inputValidations:this_field_is_required")
        ),
        password:isEdit? Yup.string(): Yup.string().required(
          t("inputValidations:this_field_is_required")
        ),
        companyName: Yup.string().required(
          t("inputValidations:this_field_is_required")
        ),
        companySize: Yup.string().required(
          t("inputValidations:this_field_is_required")
        ),
      
       
      }),
      initialValues: {
        fullName: user?.fullName || "",
        companyName: user?.companyName || "",
        companySize: user?.companySize || "",
        email: user?.email || "",
        password: user?.password || "",
    
      },
      onSubmit: (values, actions) => {
        actions.setSubmitting(true)
        dispatch(
          userCreatApi(
            values,
            actions.setSubmitting,
            (res) => {
              closeModal()
              dispatch(
                userListApi({
                  limit: 1000,
                  skip: 0
                })
              )
            },
            console.log,
            user
          )
        )
      }
    })

  return (
    <Stack direction="column" component="form" onSubmit={handleSubmit}>
    {/* <ProfileItemsHeading title={t("common:edit_profile")} /> */}
    <Stack direction="column" spacing={2} sx={{ p: 2, mt: 2 }}>
      <TextField
        label={t("common:full_name")}
        {...getFieldProps("fullName")}
        error={Boolean(touched.fullName && errors.fullName)}
        helperText={touched.fullName && errors.fullName}
      />
     
      <TextField
        label={t("common:companyName")}
        {...getFieldProps("companyName")}
        error={Boolean(touched.companyName && errors.companyName)}
        helperText={touched.companyName && errors.companyName}
      />
      <TextField
        label={t("common:companySize")}
        {...getFieldProps("companySize")}
        error={Boolean(touched.companySize && errors.companySize)}
        helperText={touched.companySize && errors.companySize}
        select
      >
        {[`1-9`, `10-99`, `100-499`, `>500`].map((item, key) => (
          <MenuItem key={key} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label={t("common:email")}
        {...getFieldProps("email")}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
        disabled={isEdit}
      />
     { !isEdit && <TextField
        label={t("common:password")}
        {...getFieldProps("password")}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
      />}
     
    </Stack>
    <Stack direction="row" spacing={2} sx={{ mt: 2,pl:2 }}>
        <Button variant="outlined" onClick={closeModal}>
          {t("common:cancel")}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
        >
          {/* {isEdit ? t("common:edit_project") : t("common:create_project")} */}
          {isEdit ? "Edit User" : "Create User"}
        </LoadingButton>
      </Stack>
  </Stack>

  )
}

export default AddUser
