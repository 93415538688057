import * as React from "react"
import * as Yup from "yup"

import {
  Button,
  IconButton,
  List,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { StateType, store } from "@/redux"

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { LoadingButton } from "@mui/lab"
import ProfileItemsHeading from "./profile-items-heading"
import UserProfile from "."
import { authSuccess } from "@/redux/auth/actions"
import { updateProfile } from "@/redux/auth/services"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import { useGlobalContext } from "@/contexts/g-context"
import { useTranslation } from "react-i18next"

interface IFormikValues {
  fullName: string
  email: string
  companyName: string
  companySize: string
  roleInCompany: string
}

interface IUserEditProfileProps {}

const tokenSelector = (state: StateType): string | null => {
  return state.auth.authToken
}
const userSelector = (state: StateType): any => {
  return state.auth.user
}

const UserEditProfile: React.FunctionComponent<IUserEditProfileProps> = (
  props
) => {
  const { t } = useTranslation(["common", "inputValidations"])
  const dispatch = useDispatch()

  const { showModal, closeModal } = useGlobalContext()

  const backToProfile = () => {
    closeModal()
    showModal(<UserProfile />)
  }

  const currUser = userSelector(store.getState())

  const {
    isSubmitting,
    setErrors,
    errors,
    touched,
    handleSubmit,
    getFieldProps
  } = useFormik<IFormikValues>({
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(
        t("inputValidations:this_field_is_required")
      ),
      companyName: Yup.string().required(
        t("inputValidations:this_field_is_required")
      ),
      companySize: Yup.string().required(
        t("inputValidations:this_field_is_required")
      ),
      roleInCompany: Yup.string().required(
        t("inputValidations:this_field_is_required")
      ),
      email: Yup.string()
        .email(t("inputValidations:please_insert_valid_email"))
        .required(t("inputValidations:this_field_is_required"))
    }),
    initialValues: {
      fullName: currUser?.fullName || "",
      email: currUser?.email || "",
      companyName: currUser?.companyName || "",
      companySize: currUser?.companySize || "",
      roleInCompany: currUser?.roleInCompany || ""
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true)
      dispatch(
        updateProfile(
          values,
          ({ data }) => {
            backToProfile()
            const token = tokenSelector(store.getState())
            if (token && data.user) dispatch(authSuccess(token, data.user))
          },
          (err) => {
            setErrors({ fullName: err.message })
          }
        )
      )
    }
  })

  return (
    <Stack direction="column" component="form" onSubmit={handleSubmit}>
      <ProfileItemsHeading title={t("common:edit_profile")} />
      <Stack direction="column" spacing={2} sx={{ p: 2, mt: 2 }}>
        <TextField
          label={t("common:full_name")}
          {...getFieldProps("fullName")}
          error={Boolean(touched.fullName && errors.fullName)}
          helperText={touched.fullName && errors.fullName}
        />
        <TextField
          label={t("common:email")}
          {...getFieldProps("email")}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />
        <TextField
          label={t("common:companyName")}
          {...getFieldProps("companyName")}
          error={Boolean(touched.companyName && errors.companyName)}
          helperText={touched.companyName && errors.companyName}
        />
        <TextField
          label={t("common:companySize")}
          {...getFieldProps("companySize")}
          error={Boolean(touched.companySize && errors.companySize)}
          helperText={touched.companySize && errors.companySize}
          select
        >
          {[`1-9`, `10-99`, `100-499`, `>500`].map((item, key) => (
            <MenuItem key={key} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("common:roleInCompany")}
          {...getFieldProps("roleInCompany")}
          error={Boolean(touched.roleInCompany && errors.roleInCompany)}
          helperText={touched.roleInCompany && errors.roleInCompany}
          select
        >
          {[
            `Architect`,
            `Building Analyst`,
            `Mechanical Engineer`,
            `Consultant`,
            `Researcher`,
            `Student`,
            `Others`
          ].map((item, key) => (
            <MenuItem key={key} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <Button variant="outlined" sx={{ px: 6 }} onClick={backToProfile}>
          {t("common:cancel")}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          {t("common:submit")}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default UserEditProfile
