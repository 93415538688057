import * as actionTypes from "./actions"

import { Reducer } from "redux"

type StateType = {
  isLoading: boolean
  isAuthenticated: boolean
  authToken: string | null
  user: any | null
}

const initial: StateType = {
  isLoading: true,
  isAuthenticated: false,
  authToken: null,
  user: null
}

const reducer: Reducer<StateType> = (state = initial, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        authToken: action.payload.token,
        user: action.payload.user
      }
    case actionTypes.AUTH_FAILURE:
      return {
        isLoading: false,
        isAuthenticated: false,
        authToken: null,
        user: null
      }
    case actionTypes.AUTH_LOGOUT:
      return {
        isLoading: false,
        isAuthenticated: false,
        authToken: null,
        user: null
      }
    default:
      return state
  }
}

export default reducer
