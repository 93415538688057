import * as React from "react";

import {
  Button,
  Grid,
  Typography,
  alpha,
} from "@mui/material";

import { Box, } from "@mui/material";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HeaderProjectToolbar from "@/containers/page-wrapper/header-projects-toolbar";
import PageWrapper from "@/containers/page-wrapper";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { useNavigate, } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProjectsPageProps {}




const AdminDashboard: React.FunctionComponent<IProjectsPageProps> = (props) => {
 
  const navigate = useNavigate();
  const { t } = useTranslation("routes");

  return (
    <PageWrapper sx={{
        bgcolor: (t) => alpha(t.palette.grey[300], 0.3),
        height:"100vh"
    }} title={t("projects")}>
      <HeaderProjectToolbar />
      <Box sx={{ height: 24 }}></Box>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <Button
            component="button"
            onClick={() => navigate("/dashboard/projects")}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              height: 192,
              bgcolor: (t) => alpha(t.palette.common.white, 0.9),
              color: (t) => t.palette.primary.main,
              borderRadius: 2,
              border: 0,
              [`&:hover`]: {
                bgcolor: (t) => alpha(t.palette.common.white, 0.9),
              },
            }}
          >
            <TopicOutlinedIcon
              style={{ height: "30px", width: "30px" }}
              sx={{ mb: 1 }}
            />
            <Typography component="span">{"Project Management"}</Typography>
          </Button>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <Button
            component="button"
            onClick={() => navigate("/users")}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              height: 192,
              bgcolor: (t) => alpha(t.palette.common.white, 0.9),
              color: (t) => t.palette.primary.main,
              borderRadius: 2,
              border: 0,
              [`&:hover`]: {
                bgcolor: (t) => alpha(t.palette.common.white, 0.9),
              },
            }}
          >
            <GroupOutlinedIcon
              style={{ height: "30px", width: "30px" }}
              sx={{ mb: 1 }}
            />
            <Typography component="span">{"User Management"}</Typography>
          </Button>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default AdminDashboard;
