import { Navigate, Outlet, Route, Routes } from "react-router-dom"

import AdminDashboard from "@/pages/admin-dashboard/AdminDashboard"
import LazyRoute from "./LazyRoute"
import PrivateRoute from "./PrivateRoute"
import React from "react"
import RoleBasedRoutes from "./RoleBasedRoutes"
import RouteNames from "./routeNames"
import SuperAdminLoginPage from "@/pages/admin-login-page"
import UsersList from "@/pages/admin-dashboard/user-list"
import { authSelectors } from "@/redux/auth"
import { useAppSelector } from "@/redux"

/**
 * All routes are placed here. If you wish to lazy load a page, refer to the example below
 * @example ```js
 * const UserDashboard = React.lazy(() => import("../panels/user/pages/Dashboard/index.tsx"))
 * .
 * .
 * .
 * <Route path="dashboard" element={<LazyRoute element={<UserDashboard />} />} />
 * ```
 * **Important note:**
 * You must export default the page and import that default export with `React.lazy` in order for lazy loading to work.
 */

//Login
const Login = React.lazy(() => import("@/pages/login-page"))

//ForgetPassword Pages
const ForgetPassword = React.lazy(() => import("@/pages/forget-password/email"))
//Register
const Register = React.lazy(() => import("@/pages/register-page"))

//Dashboard
const DashboardLayout = React.lazy(() => import("@/pages/dashboard/layout"))

//Projects
const Projects = React.lazy(() => import("@/pages/dashboard/projects"))
const SingleProject = React.lazy(
  () => import("@/pages/dashboard/projects/single")
)

const RouterConfig = () => {
  const isAuthenticated = useAppSelector(authSelectors.isAuthSelector)
  const { login, signUp, dashboard, forgetPassword, superAdminLogin } =
    RouteNames
  return (
    <Routes>
      <Route path={"/"}>
        <Route
          index
          element={
            <LazyRoute
              element={
                <Navigate to={isAuthenticated ? dashboard.index : login} />
              }
            />
          }
        />
        <Route path={login} element={<LazyRoute element={<Login />} />} />
        <Route
          path={superAdminLogin}
          element={<LazyRoute element={<SuperAdminLoginPage />} />}
        />
        <Route path={signUp} element={<LazyRoute element={<Register />} />} />
        <Route path={forgetPassword.index}>
          <Route index element={<LazyRoute element={<ForgetPassword />} />} />
          <Route
            path={forgetPassword.withToken}
            element={<LazyRoute element={<ForgetPassword />} />}
          />
        </Route>
      </Route>
      <Route
        element={<LazyRoute element={<PrivateRoute element={<Outlet />} />} />}
      >
        <Route
          path={dashboard.adminDashboard}
          element={
            <RoleBasedRoutes
              element={<LazyRoute element={<AdminDashboard />} />}
            />
          }
        />

        <Route
          path={dashboard.users}
          element={
            <RoleBasedRoutes element={<LazyRoute element={<UsersList />} />} />
          }
        />

        <Route path={dashboard.index}>
          <Route
            index
            element={
              <LazyRoute element={<Navigate to={dashboard.projects.index} />} />
            }
          />
          <Route
            path={dashboard.projects.index}
            element={<LazyRoute element={<Projects />} />}
          />
          <Route
            path={dashboard.projects.index}
            element={<LazyRoute element={<DashboardLayout />} />}
          >
            <Route path={dashboard.projects.show.index}>
              <Route index element={<Navigate to={`location`} />} />
              <Route
                path={dashboard.projects.show.tab}
                element={<LazyRoute element={<SingleProject />} />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default RouterConfig
