import * as React from "react"

import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  alpha
} from "@mui/material"

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import { ModalGravity } from "@/components/reusables/modal/interface"
import PurchaseCardView from "./card-view"
import PurchaseStatus from "./status"
import humanDate from "@/utils/humanDate"
import { useGlobalContext } from "@/contexts/g-context"
import { useTranslation } from "react-i18next"

const CustomDivider = () => (
  <Box
    sx={{
      width: "2px",
      height: 20,
      bgcolor: (t) => alpha(t.palette.common.black, 0.1)
    }}
  />
)

export interface IPurchase {
  country: string
  createdAt: string
  creatorName: string
  creatorRole: string
  energyCode: string
  isActive: boolean
  isPaid: boolean
  price: number
  name: string
  paymentAmount: number
  discount: number
  discountCode: string
  discountPercentage: number
  paymentDate: string
  primaryBuildingType: string
  step: number
  unit: string
  updatedAt: string
  _id: string
}

interface IPurchaseCardProps {
  item: IPurchase
}

interface IPurchaseTitleVal {
  title: string
  val: React.ReactNode
  direction?: "column" | "row"
  justifyContent?: string
}

export const PurchaseTitleVal: React.FunctionComponent<IPurchaseTitleVal> = ({
  title,
  val,
  direction = "column",
  justifyContent = "space-between"
}) => {
  return (
    <Stack direction={direction} justifyContent={justifyContent} spacing={1}>
      <Typography
        component="span"
        sx={{
          color: (t) => alpha(t.palette.common.black, 0.6),
          mr: direction === "row" ? 2 : 0
        }}
      >
        {title}
      </Typography>
      {val}
    </Stack>
  )
}

const PurchaseCard: React.FunctionComponent<IPurchaseCardProps> = ({
  item
}) => {
  //Getting global context
  const { showModal, closeModal } = useGlobalContext()

  //Getting translation object
  const { t } = useTranslation(["common"])

  const changeLayout = (
    component: React.ReactNode,
    gravity: ModalGravity = "modal"
  ) => {
    closeModal()
    showModal(component, gravity)
  }

  return (
    <Stack
      direction="column"
      sx={{
        position: "relative",
        p: 2,
        borderTop: 5,
        borderBottom: 5,
        borderColor: (t) => alpha(t.palette.common.black, 0.04),
        [`&:last-child`]: {
          borderTop: 0
        }
      }}
      spacing={1}
    >
      <Typography
        variant="headingBold"
        sx={{ fontWeight: "bold", fontSize: 20 }}
      >
        {item.name}
      </Typography>
      <Stack direction="row" alignItems={"center"} spacing={1}>
        <PurchaseStatus status={item.isPaid ? "Paid" : "NotPaid"} />
        <CustomDivider />
        <Typography>{humanDate(item.createdAt, "MMM - D, YYYY")}</Typography>
        <CustomDivider />
        <Typography>{item?.paymentAmount?.withPriceUnit()}</Typography>
        <Typography>
          {t("common:discount")} : {item?.discount?.withPriceUnit()}
        </Typography>
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          right: 16,
          top: "50%",
          transform: "translateY(-50%)"
        }}
        onClick={() => changeLayout(<PurchaseCardView item={item} />)}
      >
        <ArrowForwardIosRoundedIcon />
      </IconButton>
    </Stack>
  )
}

export default PurchaseCard
