import * as React from "react"

import { Box } from "@mui/material"

interface IMiniContainerProps {
  width?: number
}

const MiniContainer: React.FunctionComponent<IMiniContainerProps> = ({
  children,
  width = 320
}) => {
  return (
    <Box sx={{ mx: "auto", width, maxWidth: "98%", my: 8 }}>{children}</Box>
  )
}

export default MiniContainer
